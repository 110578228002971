import { useMemo } from "react";
import { trackFullStoryEvent } from "@expert/monitoring";
import type { Logger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { SendReactiveMessagePayload } from "@expert/shared-types";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import { getAppName, useGaiaStore, useGaiaWebsocket } from "@expert/gaia";
import { addFailureMessage, addMessage, useTimelineStore } from "../state";
import type { SendMessageProps } from "../shared-types";

export const useSendExpertAssistMessage = (loggerProp: Logger) => {
    const websocketObj = useGaiaWebsocket();
    const { subscriptionStatus } = useGaiaStore();
    const { dispatcher } = useReactAnalytics();
    const {
        features: {
            isOzmoMessagingEnabled,
            isReactiveCallContextEnabled,
            isReactiveCallContextV2Enabled,
            isSalesFAQEnabled,
        },
    } = useFeatures<TimelineFeatures>();
    const { callSid, expertId, lob, partner, sessionId } = useTimelineStore();

    const logger = useMemo(() => loggerProp.child({ module: "useSendExpertAssistMessage" }), [loggerProp]);

    const sendMessage = ({ id, metadata, text, type }: SendMessageProps) => {
        const messageId = id ?? crypto.randomUUID();
        const correlationId = crypto.randomUUID();
        const appName = getAppName({
            isOzmoMessagingEnabled,
            isReactiveCallContextEnabled,
            isReactiveCallContextV2Enabled,
            isSalesFAQEnabled,
        });

        let payload: SendReactiveMessagePayload = {
            action: "orchestrator-invoke",
            message: text,
            messageId,
            partner,
            sessionId: callSid ?? sessionId,
            sessionGroupId: sessionId,
            userId: expertId,
            userType: "expert",
            correlationId,
            appName,
        };

        // TODO: mcafee should not break if we pass lob 🫠 dependency w/ gaia team
        if (partner !== "mcafee" && lob) {
            payload = { ...payload, lob };
        }

        addMessage({ id: messageId, text, type, isUnread: false, metadata });

        if (!websocketObj) {
            logger.error({ payload, websocketObj }, "There is no websocket obj");
            const failureMessage = addFailureMessage("connectionFailure");
            void dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
                senderType: "system",
                messageType: failureMessage.type,
                messageId: failureMessage.id,
                message: failureMessage.text,
            });
            return;
        }

        if (subscriptionStatus[sessionId] !== "subscribed") {
            // TODO: Currently, this attempts to resubscribe + sends error message
            // Should we attempt to resubscribe + await + resend message?
            void websocketObj.subscribeSessionToGaia({
                sessionId,
                callSid,
                partner,
                sendJsonMessage: websocketObj.sendJsonMessage,
                logger,
            });
            logger.warn("Expert session not subscribed, subscribing now");
            const failureMessage = addFailureMessage("connectionFailure");
            void dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
                senderType: "system",
                messageType: failureMessage.type,
                messageId: failureMessage.id,
                message: failureMessage.text,
            });
            return;
        }
        websocketObj.sendJsonMessage(payload);
        void dispatcher.dispatchBusinessEvent("MessageSent", {
            senderType: "expert",
            messageType: type,
            messageId,
        });
        logger.info({ payload }, "Message sent to gaia");
        trackFullStoryEvent("ExpertAssistMessageSent", { sessionId, callSid });
    };
    return sendMessage;
};
