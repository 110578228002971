import { getLogger } from "@expert/logging";
import React, { useEffect, useState } from "react";
import { AuthProvider, hasAuthParams, useAuth } from "react-oidc-context";
import { type User } from "oidc-client-ts";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { GlobalLoader } from "@expert/common-ui";
import { setIdentityAsTestUser } from "../../analytics";
import { GlobalError } from "../../common-ui";
import { env } from "../../../config";
import { SUPPORTED_QS_PARAMS } from "../../shared-utils/constants";
import { ErrorText } from "./elements";
import { userManagerSettings } from "./userManagerSettings";

interface AuthenticationProviderProps {
    children?: React.ReactNode;
}

interface AuthHandlerProps {
    children?: React.ReactNode;
}

function useExchangeQueryParams() {
    const storedQueryParams = localStorage.getItem("queryParams");
    useEffect(() => {
        const currentQueryParams = new URLSearchParams(window.location.search);
        const supportedQueryParams = SUPPORTED_QS_PARAMS.reduce((params, supportedKey) => {
            const supportedQsParam = currentQueryParams.get(supportedKey)?.substring(0, 250);
            return supportedQsParam ? { ...params, [supportedKey]: supportedQsParam } : params;
        }, {});

        localStorage.setItem("queryParams", new URLSearchParams(supportedQueryParams).toString());
    });

    return storedQueryParams;
}

export function AuthenticationProvider({ children }: AuthenticationProviderProps) {
    const previousQueryParams = useExchangeQueryParams();

    const onSigninCallback = (user?: User): Promise<void> | void => {
        window.history.replaceState({}, document.title, window.location.pathname);
        const baseUrl = user?.url_state ?? window.location.pathname;
        const fullUrl = previousQueryParams ? `${baseUrl}?${previousQueryParams}` : baseUrl;

        if (user?.url_state) {
            window.location.replace(fullUrl);
        } else {
            window.history.replaceState({}, document.title, fullUrl);
        }
    };

    return (
        <AuthProvider {...userManagerSettings} onSigninCallback={onSigninCallback}>
            <AuthHandler>{children}</AuthHandler>
        </AuthProvider>
    );
}

const logger = getLogger({ module: "AuthenticationProvider" });

function AuthHandler({ children }: AuthHandlerProps) {
    const auth = useAuth();
    const [checkingIfTestUser, setCheckingIfTestUser] = useState<boolean>(true);

    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator) {
            logger.debug({ auth }, "auth login redirect");
            void auth.signinRedirect({
                nonce: crypto.randomUUID(),
                url_state: window.location.pathname === "/" ? undefined : window.location.href,
            });
        }
    }, [auth]);

    useEffect(() => {
        if (auth.isLoading) {
            return;
        }

        const checkIfUserIsTestUser = async () => {
            const isTestUser = await getFeature<boolean>("EWP-isTestUser", {
                environment: env.mode,
                channel: env.channel,
                employeeId: auth.user?.profile.employeeID ?? auth.user?.profile.userPrincipalName ?? "",
            });

            logger.info(`(Worker): isTestUser: '${isTestUser}'`);
            if (isTestUser) {
                setIdentityAsTestUser();
            }

            setCheckingIfTestUser(false);
        };

        void checkIfUserIsTestUser();
    }, [auth.isLoading, auth.user]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <GlobalLoader msg="Signing you in..." />;
        case "signoutRedirect":
            return <GlobalLoader msg="Signing you out..." />;
        case undefined:
        case "signinRedirect":
        case "signinPopup":
        case "signoutPopup":
        case "signoutSilent":
        case "signinResourceOwnerCredentials":
            break;
    }

    if (auth.error) return <GlobalError title="An error occurred while signing you in" />;
    if (auth.isLoading) return <GlobalLoader msg="Signing you in..." />;
    if (checkingIfTestUser) return <GlobalLoader msg="Checking identity..." />;
    if (auth.isAuthenticated) return <>{children} </>;
    return <ErrorText>Not Authenticated</ErrorText>;
}
