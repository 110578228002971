import { GaiaWebsocketProvider } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { Button, Flex, Group } from "@mantine/core";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { GlobalLoader } from "@expert/common-ui";
import { useAnalytics } from "../analytics";
import { env } from "../../config";
import { useAccessToken } from "../auth";
import { GlobalError, useToast } from "../common-ui";
import { Workspace } from "../components";
import { SolveFeatureFlags } from "../features";
import { RenderError, errorEventBus, userCache } from "../shared-utils";
import { ExpertWorkspaceWebSocketProvider } from "../websocket";
import { useAppConfig } from "../config";
import { SERVICE_PROVIDER_QS_KEY, USER_FRIENDLY_NAME_QS_KEY } from "../shared-utils/constants";
import { AgentSdkProvider } from "../sdk/agent/AgentSdkProvider";
import { EvlDisconnectedErrorPage } from "../sdk/agent/voice/EvlDisconnectedErrorPage";
import { assertServiceProvider, type ServiceProvider } from "../shared-types/serviceProvider";
import { sdkEventBus } from "../sdk";
import { freezeAppConfigSingleton } from "../config/appConfigSingleton";

const logger = getLogger({ module: "Home" });

const WorkspaceHandled = withErrorBoundary(Workspace, { FallbackComponent: RenderError });

const serviceProvidersOverride = (() => {
    if (import.meta.env.MODE === "production") return;
    const values = new URLSearchParams(window.location.search).getAll(SERVICE_PROVIDER_QS_KEY);
    if (values.length === 0) return;
    for (const value of values) assertServiceProvider(value);
    return values as ServiceProvider[];
})();

export default function Home(): React.JSX.Element {
    const userFriendlyName = new URLSearchParams(window.location.search).get(USER_FRIENDLY_NAME_QS_KEY) ?? undefined;

    const toast = useToast();

    const [isFetchingFeatures, setIsFetchingFeatures] = useState<boolean>(true);
    const [hdxEnabled, setHdxEnabled] = useState<boolean>(false);
    const { dispatcher } = useAnalytics();
    const [tokenLoading, tokenError, token] = useAccessToken(userFriendlyName);
    const [serviceProviders, setServiceProviders] = useState<ServiceProvider[]>(["TWILIO"]);
    const [evlDisconnected, setEvlDisconnected] = useState(false);
    const [useSmartEVL, setUseSmartEVL] = useState(false);

    // TODO: Remove Twilio from here
    const { loading: appConfigLoading, error: appConfigError, appConfig } = useAppConfig();

    useEffect(() => {
        const errorUnsub = errorEventBus.on("error-occurred", (error) => {
            logger.error({ err: error }, "Error running loading action:");

            if (error instanceof Error) {
                toast.error(`An unexpected error occurred: ${error.message}`);
            } else {
                toast.error("An unexpected error occurred");
            }
        });

        const evlDisconnectedUnsub = sdkEventBus.on("evl_disconnected", (_payload) => {
            logger.info("EVL was disconnected, notifying the expert so they can refresh or reconnect to EVL.");
            setEvlDisconnected(true);
        });

        const errorBoundaryUnsub = errorEventBus.on("error-boundary-hit", (error) => {
            logger.error({ err: error }, "Error Boundary caught an error:");
        });

        return () => {
            errorUnsub();
            errorBoundaryUnsub();
            evlDisconnectedUnsub();
        };
    }, [toast]);

    useEffect(() => {
        if (tokenLoading) {
            return;
        }

        const fetchFeatures = async () => {
            const context = {
                environment: env.mode,
                channel: env.channel,
                employeeId: userCache.employeeId,
            };

            const [isHDXEnabled, serviceProvidersFeature, useSmartEVLFeature] = await Promise.all([
                getFeature<boolean>("EWP-hdxEnabled", context),
                getFeature<ServiceProvider[]>("EWP-ServiceProviders", context),
                getFeature<boolean>("EWP-smartEVL", context),
            ]);

            setServiceProviders((serviceProvidersFeature?.valueOf() ?? ["TWILIO"]) as ServiceProvider[]);
            setHdxEnabled(isHDXEnabled?.valueOf() ?? false);
            setUseSmartEVL(useSmartEVLFeature?.valueOf() ?? false);
            setIsFetchingFeatures(false);
            logger.info(`(HDX): Feature flag enablement: ${isHDXEnabled?.valueOf()}`);
            logger.info(
                `(ServiceProviders): Service Providers feature flag: ${serviceProvidersFeature?.valueOf().toString()}`,
            );
        };

        void fetchFeatures();
    }, [tokenLoading]);

    const handlePageReload = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    };

    useEffect(() => {
        if (!tokenError && tokenLoading) {
            void dispatcher.dispatchBusinessEvent("Authenticating");
        }
    }, [dispatcher, tokenError, tokenLoading]);

    if (evlDisconnected) {
        return <EvlDisconnectedErrorPage />;
    }

    if (!tokenError && tokenLoading) {
        return <GlobalLoader msg="Acquiring access token..." />;
    }

    if (isFetchingFeatures) {
        return <GlobalLoader msg="Checking if experimental features should be enabled..." />;
    }

    if (tokenError) {
        logger.error({ err: tokenError }, "Error acquiring access token");
        return (
            <GlobalError msg="You can also contact Service Desk for help or if it’s your first time using Expert Workspace.">
                <Group>
                    <Button mt="sm" onClick={handlePageReload} variant="outline" c="white" color="asurion.5">
                        Refresh
                    </Button>
                </Group>
            </GlobalError>
        );
    }

    if (!appConfigError && (appConfigLoading || !appConfig)) {
        void dispatcher.dispatchAction("LoadingConfiguration", "LoadingConfiguration");
        return <GlobalLoader msg="Loading configuration..." />;
    }

    if ((!appConfigLoading && !appConfig) || appConfigError) {
        logger.error({ err: appConfigError }, "Error loading configuration");
        return (
            <GlobalError title="Error loading Workspace configuration">
                <Button mt="sm" onClick={handlePageReload}>
                    Refresh
                </Button>
            </GlobalError>
        );
    }

    freezeAppConfigSingleton(appConfig!);

    const { ssoAccessToken, identity } = userCache;
    if (!ssoAccessToken) return <h1>DEBUG: NO token</h1>;
    if (!identity) return <h1>DEBUG: NO identity</h1>;

    const { employeeId } = userCache;

    return (
        <Flex flex={1} direction="column" h="100%" w="100%">
            {/* TODO: Move relevant providers up once we have several pages */}
            <AgentSdkProvider
                employeeNum={employeeId}
                userFriendlyName={userFriendlyName}
                token={token}
                hdxEnabled={hdxEnabled}
                useSmartEVL={useSmartEVL}
                serviceProviders={serviceProvidersOverride ?? serviceProviders}
            >
                {/* TODO: Get client from the worker attributes maybe? */}
                <ExpertWorkspaceWebSocketProvider identity={identity} token={ssoAccessToken}>
                    <GaiaWebsocketProvider
                        identity={identity}
                        application="expert-workspace"
                        token={ssoAccessToken}
                        url={env.gaiaWebsocketUrl}
                        logger={logger}
                    >
                        <SolveFeatureFlags employeeId={employeeId} logger={logger}>
                            <WorkspaceHandled />
                        </SolveFeatureFlags>
                    </GaiaWebsocketProvider>
                </ExpertWorkspaceWebSocketProvider>
            </AgentSdkProvider>
        </Flex>
    );
}
